import { Button, Col, Popover, Row, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { filterComponentFactory } from './filterItems';
import type { NormalProgramme } from './normalProgramme';
import styles from './normalProgramme.module.less';
import { RenderByCondition } from '../renderByCondition';
import { ArrowDownOutlined } from '@ant-design/icons';

interface NormalProgrammeComponentProps {
  readonly store: NormalProgramme;
  readonly className?: string;
  readonly style?: React.CSSProperties;
}

const gapXs = 8;

@observer
export class NormalProgrammeComponent extends React.Component<NormalProgrammeComponentProps> {
  render() {
    const { className = '', style = {}, store } = this.props;
    const { notCollapseData } = store;

    return (
      <div
        className={[styles.contentBase, className].filter(Boolean).join(' ')}
        style={style}
      >
        <Row
          style={{ flex: 1 }}
          gutter={[gapXs, gapXs]}
        >
          {notCollapseData.map((item) => (
            <Col
              key={item.field}
              span={item.span}
            >
              {filterComponentFactory(item)}
            </Col>
          ))}
        </Row>
        <ButtonContainer store={store} />
      </div>
    );
  }
}

@observer
class ButtonContainer extends React.Component<{ store: NormalProgramme }> {
  render() {
    const {
      store: { button, reset, isSearch, handleSearch, showButton, collapseData },
    } = this.props;
    return (
      <RenderByCondition show={showButton}>
        <Col className={styles.btn}>
          <RenderByCondition show={!button}>
            <Space
              style={{ width: '100%' }}
              direction="vertical"
              size={gapXs}
            >
              {collapseData.length > 0 ? (
                <Popover
                  arrow={{ pointAtCenter: true }}
                  content={
                    <Row
                      className={styles.collapseContainer}
                      gutter={[gapXs, gapXs]}
                    >
                      {collapseData.map((item) => (
                        <Col
                          key={item.field}
                          span={12}
                        >
                          {filterComponentFactory(item)}
                        </Col>
                      ))}
                    </Row>
                  }
                  overlayInnerStyle={{ width: 800 }}
                  placement="bottomRight"
                  title={null}
                  trigger="click"
                >
                  <Button type="text">
                    更多
                    <ArrowDownOutlined />
                  </Button>
                </Popover>
              ) : null}
              <Button
                block
                loading={isSearch}
                onClick={handleSearch}
                type="primary"
              >
                查询
              </Button>
              <Button
                block
                onClick={reset}
              >
                重置
              </Button>
            </Space>
          </RenderByCondition>
        </Col>
      </RenderByCondition>
    );
  }
}
