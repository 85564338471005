import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { SearchListStructure } from '../../utils/searchListStructure';
import { Refund } from './modal/refund';
import { RefundRecord } from './modal/refundRecord';
import Model from './model';

const store = new Model();

const UserManagement = observer(() => {
  // const { pageStore, refundStore, refundRecordStore } = store;
  const { pageStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      {/* TODO 后端说没有退款 */
      /*  <Refund store={refundStore} />
      <RefundRecord store={refundRecordStore} /> */}
    </div>
  );
});

export default UserManagement;
