import type { BaseData } from './request';
import { request } from './request';
import { uniqueId } from 'lodash';
import OBS from 'esdk-obs-browserjs';

const host = 'https://anchor-circle.obs.cn-south-1.myhuaweicloud.com';
interface CloudPolicy {
  aliCloudInfoVo?: AliCloudInfoVo;
  huaWeiCloudInfoVo: HuaWeiCloudInfoVo;
  activeType: number;
  validated: boolean;
  result?: any;
}

interface AliCloudInfoVo {
  accessKeyId: string;
  dir: string;
  host: string;
  region: string;
  accessKeySecret: string;
  token: string;
  endPoint: string;
  bucketName: string;
  validated: boolean;
  result?: any;
}

interface HuaWeiCloudInfoVo {
  accessKey: string; // ak
  secretKey: string; // sk
  securityToken: string; // 临时securitytoken
  endPoint: string;
  host: string;
  dir: string; // 目录
  bucketName: string;
}

export interface UploadFileItem {
  blob: Blob | File;
  id: string | number;
  name: string;
}

export interface UploadData {
  params?: Array<{ capacity: number; name?: string; parentId: string }>; // 图片空间校验参数
  type?: number; // 上传文件类型 1.图片(默认)，2.视频
  obsConfigId: number; // 配置类型id
  callType?: number; // 调用云的方式: 1-SDK调用 2-API调用, 不传默认1
}

/**
 * 上传包含的额外信息
 * beforeUploadCb、completeUploadCb、progressCb、validatedCb 只在批量上传里用
 */
export interface UploadExtendParam {
  isAnon?: boolean; // 是否面校验
  fileName?: string; // 保留文件名，压缩后可能会成为blob,将会丢失文件名, 必须单独传
  beforeUploadCb?: (file: File | UploadFileItem) => Promise<void> | void; // 分片上传 上传单个文件前回调
  completeUploadCb?: (url: string, file: File | UploadFileItem) => Promise<void> | void; // 分片上传 上传单个文件成功后回调
  progressCb?: (progress: number, file: UploadFileItem) => Promise<void> | void; // 分片上传 上传进度回调
  validatedCb?: (res: any['data']) => Promise<void> | void;
}

/**
 * 查询云临时校验信息（免校验接口）
 * @param data
 * @returns
 */
export const getUploadTokenAnon = async (data: UploadData): Promise<CloudPolicy> => {
  const res = await request<BaseData<CloudPolicy>>({
    url: '/api/goodsPic/rest/anon/policy/getALlCloudPolicy',
    method: 'POST',
    data,
  });
  return res?.data;
};

/**
 * 查询云临时校验信息（免图片管家校验）
 * @param data
 * @returns
 */
export const getUploadTokenWithOutCheck = async (data: UploadData): Promise<CloudPolicy> => {
  const res = await request<BaseData<CloudPolicy>>({
    url: '/api/goodsPic/rest/policy/getCloudPolicyWithOutCheck',
    method: 'POST',
    data,
  });
  return res?.data;
};

/**
 * 查询云临时校验信息（需要校验空间大小等信息 主要图片空间上传使用）
 * @param data
 * @returns
 */
export const getUploadToken = async (data: UploadData): Promise<CloudPolicy> => {
  const res = await request<BaseData<CloudPolicy>>({
    url: '/api/goodsPic/rest/policy/getALlCloudPolicy',
    method: 'POST',
    data,
  });
  return res?.data;
};

const getObsClient = () =>
  new OBS({
    access_key_id: 'ISXU10SBUC2XJQLZOR3U',
    secret_access_key: 'nc4DzZtLLaVW8yJwfx9aXd9ShLvGzSxiq3KhJj9c',
    server: 'obs.cn-south-1.myhuaweicloud.com',
  });
function getFileName(fileName: string): string {
  return `${uniqueId()}_${fileName.replace(/,|，| |/g, '')}`;
}

/**
 * 单个普通上传
 * @param data 校验参数
 * @param file 上传文件
 * @param extendParam 额外的参数
 * @returns
 */
export const singleUpload = async (data: UploadData, file: File | Blob, extendParam?: UploadExtendParam) => {
  const policy = extendParam?.isAnon ? await getUploadTokenAnon(data) : data.obsConfigId === 3 ? await getUploadToken(data) : await getUploadTokenWithOutCheck(data);
  if (!policy.validated && policy.result) {
    extendParam.validatedCb && extendParam.validatedCb(policy.result);
    throw '上传失败！请联系管理员或重新上传';
  }
  const _name = extendParam?.fileName || (file as File).name;
  const fileName = getFileName(_name);
  return singleUploadObs(file, fileName);
};

/**
 * 单个普通上传 华为云
 * @param aliOssPolicy 临时秘钥
 * @param file 上传文件
 * @param fileName 文件名
 * @returns
 */
export const singleUploadObs = async (file: File | Blob, fileName?: string): Promise<string> => {
  try {
    const obsClient = await getObsClient();
    await obsClient.putObject({
      Bucket: 'anchor-circle',
      Key: `pbb-pc-anchorcircle/${fileName}`,
      SourceFile: file,
    });
    console.log('url', `${host}/pbb-pc-anchorcircle/${fileName}`);
    return `${host}/pbb-pc-anchorcircle/${fileName}`;
  } catch (e) {
    console.error(e);
    throw '上传失败！请联系管理员或重新上传';
  }
};

/**
 * 单个上传（分片上传）
 * @param data
 * @param file
 * @param extendParam
 * @returns
 */
export const singlePartUpload = async (data: UploadData, file: UploadFileItem, extendParam?: UploadExtendParam): Promise<string> => {
  const policy = extendParam?.isAnon ? await getUploadTokenAnon(data) : data.obsConfigId === 3 ? await getUploadToken(data) : await getUploadTokenWithOutCheck(data);
  if (!policy.validated && policy.result) {
    extendParam.validatedCb && extendParam.validatedCb(policy.result);
    throw '上传失败！请联系管理员或重新上传';
  }
  return multipartUploadObs(policy.huaWeiCloudInfoVo, file, extendParam);
};

/**
 * 批量上传（分片上传）
 * @param data
 * @param fileList
 * @param extendParam
 * @returns  上传返回的信息，包含成功和失败 list
 */
export const multipartUpload = async (data: UploadData, fileList: UploadFileItem[], extendParam?: UploadExtendParam): Promise<string[] | string> => {
  const policy = extendParam?.isAnon ? await getUploadTokenAnon(data) : data.obsConfigId === 3 ? await getUploadToken(data) : await getUploadTokenWithOutCheck(data);
  if (!policy.validated && policy.result) {
    extendParam.validatedCb && extendParam.validatedCb(policy.result);
    throw '上传失败！请联系管理员或重新上传';
  }
  console.log('校验通过,准备上传...');
  const promiseList = [];
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    promiseList.push(multipartUploadObs(policy.huaWeiCloudInfoVo, file, extendParam));
  }
  const res: string[] = await PromiseAll(promiseList);
  console.log('multipartUpload', res);
  return res;
};
const PromiseAll = (iterator: Array<Promise<string>>): Promise<string[]> => {
  const promises: any = Array.from(iterator); // 对传入的数据进行浅拷贝，确保有遍历器
  const len = promises.length; // 长度
  const data: string[] = []; // 用来存放返回的数据数组
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    for (let i = 0; i < promises.length; i++) {
      await promises[i]
        .then((res: any) => {
          data[i] = res;
          if (i + 1 === len) {
            resolve(data);
          }
        })
        .catch(() => {
          data[i] = '';
          if (i + 1 === len) {
            resolve(data);
          }
        });
    }
  });
};

/**
 * 分段上传 华为云
 * @param aliOssPolicy
 * @param file
 * @param fileName
 * @param progressCb
 * @returns
 */
const multipartUploadObs = async (hwObsPolicy: HuaWeiCloudInfoVo, file: UploadFileItem, extendParam?: UploadExtendParam): Promise<string> => {
  extendParam?.beforeUploadCb && extendParam?.beforeUploadCb(file);
  try {
    const { host, dir, bucketName } = hwObsPolicy;
    const obsClient = await getObsClient();
    const name = getFileName(file.name);
    await obsClient.uploadFile({
      Bucket: bucketName,
      Key: `${dir}/${name}`,
      SourceFile: file.blob,
      PartSize: 1024 * 1024,
      ProgressCallback(transferredAmount: number, totalAmount: number, totalSeconds: number) {
        const p = transferredAmount / totalAmount;
        extendParam?.progressCb && extendParam?.progressCb(p, file);
      },
    });
    const url = `${host}/${dir}/${name}`;
    extendParam?.completeUploadCb && extendParam?.completeUploadCb(url, file);
    return url;
  } catch {
    throw '上传失败！请联系管理员或重新上传';
  }
};
