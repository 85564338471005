import type React from 'react';

interface RenderByConditionProps {
  readonly children: React.ReactElement;
  readonly show: boolean;
}

export const RenderByCondition = function ({ children, show }: RenderByConditionProps): React.ReactElement {
  return show ? children : null;
};
