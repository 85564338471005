import type { FilterBoolean } from './filterBoolean/filterBoolean';
import type { FilterCascader } from './filterCascader/filterCascader';
import type { FilterDate } from './filterDate/filterDate';
import type { FilterDateStartOrEnd } from './filterDate/filterDateStartOrEnd';
import type { FilterInput } from './filterInput/filterInput';
import type { FilterInputNumberGroup } from './filterInputNumberGroup/filterInputNumberGroup';
import type { FilterSelect } from './filterSelect/filterSelect';
import type { FilterTreeSelect } from './filterTreeSelect/filterTreeSelect';

/**
 * 查询项类型
 */
export enum ENUM_FILTER_ITEM_TYPE {
  input = 'input',
  inputNumberGroup = 'inputNumberGroup',
  select = 'select',
  dateRange = 'dateRange',
  dateStart = 'dateStart',
  dateEnd = 'dateEnd',
  cascader = 'cascader',
  treeSelect = 'treeSelect',
  boolean = 'boolean',
}

/**
 * 批量查询拆分符号
 */
export type ENUM_SPLIT_SYMBOL = ' ' | ',';

/**
 * 查询项data的统一格式
 */
export type ValueAndLabelData = Array<{ value: string; label: string; [key: string]: any }>;

/**
 * 查询项种类
 */
export type FilterItem = FilterInput | FilterInputNumberGroup | FilterSelect | FilterDate | FilterCascader | FilterDateStartOrEnd | FilterTreeSelect | FilterBoolean;

/**
 * 查询项参数
 */
export type FilterItemOptions = Partial<FilterItem>;

/**
 * 查询项保存类型
 */
export interface FilterItemSettingItem {
  field: string;
  label: string;
  showItem: boolean;
}

/**
 * 日期组件picker类型
 */
export enum ENUM_PICKER_TYPE {
  date = 'date',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}
