import { DatePicker, Row, Tag } from 'antd';
import { observer } from 'mobx-react';
import moment from 'dayjs';
import React from 'react';
import styles from '../filterItems.module.less';
import { FilterItemLabel } from '../utils';
import { FormatDateType } from './filterDate';
import type { FilterDate } from './filterDate';

export const FilterDateComponent = observer(({ store }: { store: FilterDate }) => {
  const { allowEmpty, startTime, endTime, placeholder, format, label, className, style, disabled, handleRangeChange, labelWidth, allowClear, required, open, containerRef, fixPanelHideNotSetTime } = store;
  const newClassName = ['filterDate', className].filter(Boolean).join(' ');
  return (
    <div
      className={newClassName}
      ref={containerRef}
      style={style}
    >
      <FilterItemLabel
        label={label}
        labelWidth={labelWidth}
        required={required}
      />
      <DatePicker.RangePicker
        allowClear={open ? false : allowClear}
        allowEmpty={allowEmpty}
        disabled={disabled}
        format={format}
        onChange={handleRangeChange}
        onOpenChange={(isOpen: boolean) => {
          fixPanelHideNotSetTime(isOpen);
          store.open = Boolean(isOpen);
        }}
        open={open}
        placeholder={placeholder}
        renderExtraFooter={() => <FilterDateDictComponent store={store} />}
        showTime={
          format === FormatDateType.defaultFormat
            ? {
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
              }
            : false
        }
        value={[startTime, endTime]}
      />
    </div>
  );
});

const FilterDateDictComponent = observer(({ store }: { store: FilterDate }) => {
  const { handleDateDictChange, realDateDict } = store;
  return (
    <Row
      className={styles.dateSelect}
      gutter={[4, 4]}
    >
      {realDateDict.map((item) => (
        <Tag
          className="egenie-secondary-content"
          color="blue"
          key={item.value}
          onClick={() => handleDateDictChange(item.value)}
        >
          {item.label}
        </Tag>
      ))}
    </Row>
  );
});
