import { TreeSelect } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { FilterItemLabel } from '../utils';
import type { FilterTreeSelect } from './filterTreeSelect';

export const FilterTreeSelectComponent = observer(({ store }: { store: FilterTreeSelect }) => {
  const {
    value,
    onChange,
    placeholder,
    allowClear,
    disabled,
    style,
    className,
    label,
    showSearch,
    labelWidth,
    popupClassName,
    popupMatchSelectWidth,
    listHeight,
    loadData,
    multiple,
    treeExpandedKeys,
    treeNodeFilterProp,
    treeData,
    onTreeExpand,
    treeCheckable,
    showCheckedStrategy,
    maxTagCount,
    treeDataSimpleMode,
    autoClearSearchValue,
    dropdownStyle,
    suffixIcon,
    switcherIcon,
    treeDefaultExpandAll,
    treeDefaultExpandedKeys,
    treeIcon,
    onSelect,
    required,
  } = store;
  return (
    <div
      className={`filterTreeSelect ${className}`}
      style={style}
    >
      <FilterItemLabel
        label={label}
        labelWidth={labelWidth}
        required={required}
      />
      <TreeSelect
        allowClear={allowClear}
        autoClearSearchValue={autoClearSearchValue}
        disabled={disabled}
        popupClassName={popupClassName}
        popupMatchSelectWidth={popupMatchSelectWidth}
        dropdownStyle={dropdownStyle}
        listHeight={listHeight}
        loadData={loadData}
        maxTagCount={maxTagCount}
        multiple={multiple}
        onChange={onChange}
        onSelect={onSelect}
        // @ts-ignore
        onTreeExpand={onTreeExpand}
        placeholder={placeholder}
        showCheckedStrategy={showCheckedStrategy}
        showSearch={showSearch}
        suffixIcon={suffixIcon}
        switcherIcon={switcherIcon}
        treeCheckable={treeCheckable}
        treeData={treeData}
        treeDataSimpleMode={treeDataSimpleMode}
        treeDefaultExpandAll={treeDefaultExpandAll}
        treeDefaultExpandedKeys={treeDefaultExpandedKeys}
        treeExpandedKeys={treeExpandedKeys}
        treeIcon={treeIcon}
        treeNodeFilterProp={treeNodeFilterProp}
        value={value}
      />
    </div>
  );
});
