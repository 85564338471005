import { Cascader } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FilterItemLabel } from '../utils';
import type { FilterCascader } from './filterCascader';

export const FilterCascaderComponent = observer(({ store }: { store: FilterCascader }) => {
  const { value, data, onChange, placeholder, allowClear, disabled, style, className, label, showSearch, loadData, fieldNames, labelWidth, required, changeOnSelect } = store;

  return (
    <div
      className={`filterCascader ${className}`}
      style={style}
    >
      <FilterItemLabel
        label={label}
        labelWidth={labelWidth}
        required={required}
      />
      <Cascader
        allowClear={allowClear}
        changeOnSelect={changeOnSelect}
        disabled={disabled}
        fieldNames={fieldNames}
        loadData={loadData}
        onChange={onChange}
        options={toJS(data)}
        placeholder={placeholder}
        showSearch={showSearch}
        value={value}
      />
    </div>
  );
});
